import PropTypes from 'prop-types'

import ModalBase from './ModalBase'

const ActionPromptModal = ({
  title = 'Huomio!',
  visible,
  onCancel,
  renderBody,
  renderFooter,
  modalProps,
  testId
}) => {
  return (
    <ModalBase
      title={title}
      isOpen={visible}
      onCancel={onCancel}
      modalProps={{
        backdrop: 'static',
        wrapClassName: 'prompt-wrap',
        modalClassName: 'prompt-modal-parent',
        className: 'prompt-modal',
        'data-testid': testId,
        ...modalProps
      }}
      renderBody={renderBody}
      renderFooter={renderFooter}
    />
  )
}

ActionPromptModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  renderBody: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  renderFooter: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  modalProps: PropTypes.object,
  testId: PropTypes.string
}

export default ActionPromptModal
