import { useCallback } from 'react'
import {
  type FieldValues,
  type Path,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
  type UseFormProps,
  type UseFormSetError
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import type { DeepPartial } from 'redux'
import type { AnyObjectSchema, AnySchema, Lazy } from 'yup'

import { parseValidationErrors } from '../../helpers/apiHelpers'
import { ActionInterruptedError } from '../../helpers/errors'

export const SUBMIT_INTERRUPTED = Symbol('SubmitInterrupted')

export const handleApiSubmitErrors = <T extends FieldValues>(error: { validationErrors: Record<string, { message: string }> | { msg: string, param: string }[] }, setError: UseFormSetError<T>, rootErrors: string[] | undefined) => {
  // This is used to prevent ToLastPathButton from proceeding with the handleClick function if e.g. a prompt interrupts and re-calls the action (case ReceiverForm: inbound invoice cost type bulk update)
  if(error instanceof ActionInterruptedError) {
    setError('root.submitInterrupted', { type: 'submitInterrupted', message: error.message })
  }
  // Set validation errors to the form
  if(error.validationErrors) {
    const validationErrors: Record<string, { message: string }> = Array.isArray(error.validationErrors)
      ? parseValidationErrors(error.validationErrors)
      : error.validationErrors
    for(const [key, validationError] of Object.entries(validationErrors)) {
      setError(key as `root.${string}` | 'root' | Path<T>, { type: 'validationError', message: validationError.message })
      if(rootErrors?.includes(key)) {
        // Set "global" errors with `rootErrors` which are cleared automatically before each submission.
        // Good for displaying custom errors for the user.
        setError(`root.${key}`, { type: 'validationError', message: validationError.message })
      }
    }
  }
}

export type UseApiFormSubmit = (event?: React.BaseSyntheticEvent) => Promise<void>

interface UseApiFormProps<T extends FieldValues> extends UseFormProps<T> {
  schema?: AnyObjectSchema | Lazy<AnySchema, unknown>
  rootErrors?: string[]
  onSubmit?: (data: T, defaultValues?: DeepPartial<Awaited<T>> | DeepPartial<T> | ((payload?: unknown) => Promise<T>)) => T | undefined | Promise<T | undefined>
  onInvalid?: SubmitErrorHandler<T>
}

const useApiForm = <T extends FieldValues>({
  schema,
  onSubmit,
  rootErrors,
  ...hookFormOptions
}: UseApiFormProps<T>) => {
  const hookFormProps = useForm<T>({
    resolver: schema ? yupResolver(schema) : undefined,
    ...hookFormOptions
  })
  const {
    reset,
    handleSubmit,
    setError,
    clearErrors
  } = hookFormProps

  const resetForm = useCallback((model?: T) => {
    clearErrors()
    return reset(model)
  }, [clearErrors, reset])

  const safeSubmit: UseApiFormSubmit = useCallback(async(event?: React.BaseSyntheticEvent) => {
    // Call stopPropagation as soon as possible because handleSubmit is async and event might get propagated before submit
    event?.stopPropagation?.()

    const submit: SubmitHandler<T> = (model: T) => {
      return Promise.resolve(clearErrors())
        .then(() => onSubmit?.(model, hookFormOptions.defaultValues))
        .then(resetForm)
        .catch(error => {
          return handleApiSubmitErrors(error, setError, rootErrors)
        })
    }

    return handleSubmit(submit, hookFormOptions.onInvalid)(event)
  }, [clearErrors, handleSubmit, hookFormOptions.defaultValues, hookFormOptions.onInvalid, onSubmit, resetForm, rootErrors, setError])

  return {
    ...hookFormProps,
    reset,
    resetForm,
    setError,
    handleSubmit,
    clearErrors,
    submit: safeSubmit,
    hookFormProps // Export hook forms properties which must be passed to FormProviders as whole
  }
}

export default useApiForm
